@import 'scss/vars.scss';

.ids-assessment-result-table-disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.loading-spinner-wrap {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.empty-notice {
  @include flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 100%;

  .icon {
    font-size: 4em;
    color: $color-green-dg;
  }

  .message {
    font-size: 1.25rem;
    font-weight: $typography-font-weight-bold;
  }
}

.ids-result-details-filter {
  margin: 1rem 0;
}

.ids-result-details-container {
  display: flex;
  justify-content: space-around;

  > .divider {
    width: 1px;
    background-color: $color-grey-400;
  }

  .ids-result-details-item {
    .ids-result-details-header {
      margin-bottom: 0.5rem;
      font-size: 14px;
      color: $color-grey-900;
      font-weight: $typography-font-weight-bolder;
    }

    .ids-result-details {
      display: flex;
      gap: 2rem;

      .ids-result-details-table {
        width: auto;

        td,
        tr,
        th,
        thead {
          border: none !important;
          padding: 0 !important;
        }
        th,
        td {
          padding-bottom: 0.25rem !important;
        }

        thead {
          tr {
            th {
              font-size: 14px;
              text-align: center;
              color: $color-grey-900;
              font-weight: $typography-font-weight-bolder;
            }
          }
        }

        tbody {
          tr {
            td:last-child {
              > .ids-result-label {
                margin-right: 0;
              }
            }
            td {
              > .ids-result-label {
                span {
                  color: $color-grey-500;
                  font-size: 14px;

                  &.ids-result-label-icon {
                    margin-left: 0.25rem;
                    color: $color-grey-550;
                  }
                }
                margin-right: 2rem;
              }

              > .ids-result-value {
                &-initial,
                &-separator,
                &-final {
                  font-size: 14px;
                  font-weight: $typography-font-weight-bold;
                  text-align: center;
                  color: $color-grey-900;

                  &.success {
                    color: $color-green-dg;
                  }
                  &.failed {
                    color: $color-red-dg;
                  }
                }

                &-separator {
                  color: $color-grey-500;
                  margin: 0 0.5rem;
                }
              }

              > .ids-result-details-enhancement-icon {
                &-final,
                &-initial {
                  margin-right: 0.5rem;
                }

                &-final {
                  color: $color-green-dg;
                }
                &-initial {
                  color: $color-blue-dg;
                }
              }

              > .ids-result-details-fail-reason-icon {
                margin-right: 0.5rem;
                color: $color-orange-dg;
              }
            }
          }
        }
      }
    }
  }
}

.ids-result-details-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1rem;
  color: $color-grey-500;
}

.ids-assessment-result-table {
  .ids-result-details-market-ready-icon {
    &-fail {
      color: $color-red-dg;
    }
    &-pass {
      color: $color-green-dg;
    }
  }
}
