@import 'scss/vars.scss';

$max-image-height: 80vh;

.ids-result-modal-panel {
  --max-modal-width-vw: 95vw;
  --max-modal-width-px: 1980px;

  &.small-display {
    --max-modal-width-vw: 50vw;
  }

  .modal-dialog {
    max-width: calc(min(var(--max-modal-width-vw), var(--max-modal-width-px)));
    width: auto;
    margin: 1.75rem auto;
    justify-content: center;

    .ids-result-modal-content {
      .ids-result-model-container {
        display: flex;
        overflow-x: hidden;

        .ids-separator {
          width: 1px;
          background-color: $color-grey-400;
          margin: 0 0.5rem;
        }

        .ids-result-image-content {
          display: flex;
          justify-content: center;
          align-items: stretch;
          max-height: 100%;
          gap: 4px;
          flex: 1;

          .ids-image-preview {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1;
            height: 100%;

            .ids-image-preview-title {
              font-size: 18px;
              color: $color-grey-900;
              font-weight: $typography-font-weight-bold;
              margin-bottom: 1rem;
            }

            .ids-image-preview-wrapper {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              flex: 1;
              max-width: 100%;

              .ids-image-preview-img {
                max-width: 100%;
                max-height: $max-image-height;

                &.transparent {
                  background-image: linear-gradient(45deg, #ccc 25%, transparent 25%),
                    linear-gradient(135deg, #ccc 25%, transparent 25%),
                    linear-gradient(45deg, transparent 75%, #ccc 75%),
                    linear-gradient(135deg, transparent 75%, #ccc 75%);
                  background-size: 25px 25px; /* Must be a square */
                  background-position: 0 0, 12.5px 0, 12.5px -12.5px, 0px 12.5px; /* Must be half of one side of the square */
                }
              }
            }
          }
        }

        .ids-result-modal-side-panel-separator {
          display: flex;
          align-items: start;
          justify-content: center;
          margin: 0 0.5rem;

          .ids-separator {
            margin: 0;
            position: relative;
            left: 50%;
            height: 100%;
          }

          .ids-result-modal-separator-toggle {
            position: relative;
            top: 50px;
            border: none;
            outline: none;
            border-radius: 50%;
            height: 24px;
            width: 24px;
            text-align: center;
            background: $color-blue-dg;
            box-shadow: 0 0 0.2rem rgba($color-blue-dg, 0.5) !important;

            &:hover {
              background: rgba($color-blue-dg, 0.95);
            }
          }
        }

        .ids-results-sidebar-panel {
          --ids-results-sidebar-width: 220px;

          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: start;
          gap: 2rem;
          overflow: hidden;
          flex-wrap: nowrap;
          width: var(--ids-results-sidebar-width);
          transition: width 0.5s ease;

          .ids-result-label {
            margin-right: 1rem;
          }

          &.ids-final-results {
            --ids-results-sidebar-width: 300px;
          }

          &.sidebar-closed {
            width: 0;
          }

          > * {
            width: var(--ids-results-sidebar-width);
          }
        }
      }
    }
  }
}
